* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: roboto, 'sans-serif';
}

body{
  height: 100vh;
}
