.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0078d4;
    color: white;
    padding: 0.5em 1em;
  }
  
  .navbar-brand {
    font-size: 1.5em;
    font-weight: 1em;
    font-family: 'Segoe UI', 'Ubuntu', 'Roboto', 'Open Sans', 'Helvetica Neue',
    sans-serif;
  }
  
  .navbar-icons {
    display: flex;
    gap: 1em;
  }
  
  .icon {
    cursor: pointer;
  }
  
  /* You can add more styles to adjust the icons, such as size, color, etc. */
  